// third-party
import { combineReducers } from "redux";

// project-imports
import menu from "./menu";
import RegisterOwnerBrandReducer from "./registerbrandowner";
// import registerBrandUser from "./registerbranduser";
import CreateRolssseeeReducer from "./createrole";
import ViewAllRolesReduncerre from "./viewallrole";
import AssignPermisionsReducer from "./assignpermissions";
import GetAllPermissionReducer from "./getallassignpermissions";
import ViewAllRedUsers from "./viewAllUsers";
import EditUserDataReducer from "./edituser";
import ViewallBrandssRedUsers from "./viewallbrand";
import BrandDetailsGetDataReducer from "./detailbrandedit";
import AllGetCateeReducers from "./allcategeory";
// import SubAllCateReducers from "./allsubcategries";
import AllCompaniesReducers from "./allcompany";
import AllCountReducers from "./allcount";
import CompanyDetailingReducer from "./companydetails";
import AllProducts from "./allProducts";
// import AllActiveCompanies from "./AllActiveCompanies";
import AllRandomCode from "./allRandom";
import AllActiveProducts from "./allActiveProducts";
import GetRolePermissions from "./rolePermissions";
// import AllBatchesReducers from "./allBatches";
// import Reports from "./reports";
// import SMSReply from "./smsreply";
import AllAssignPreDetails from "./assignpermission/allpremisesdetails";

import ExportReducerCsv from "./exportcsv";
import CSvCompanyReducer from "./csvcompany";
import CSVCategories from "./exportcsvcategory";
import AllCsvRadmon from "./exportcsvrandomcode";
// import CSvQRReducer from "./csvQRcode";
// import ReportProblemReducer from "./ReportProblemReducer";
// import ProgressReducer from "./ProgressCode";
import MapDataReducers from "./mapdata";
import Notificationgetall from "./allNotification";

import GetAllPropertyType from "./AllPropertyType/getAllPropertyType";
import GetAllActivePropertyType from "./AllPropertyType/getAllActivePropertyType";
import GetAllActiveProperty from "./AllProperty/getAllActiveProperty";
import GetAllProperty from "./AllProperty/getAllProperty";
import GetAllActiveFlatSize from "./AllFlatSize/getAllActiveFlatSize";
import GetAllAmenities from "./AllAmenities/getAllAnemities";
import GetAllActiveAmenities from "./AllAmenities/getAllActiveAnemities";
import GetAllFlatSize from "./AllFlatSize/getAllFlatSize";
import GetAllFlat from "./AllFlat/getAllFlatProperty";
import GetFlatPremesis from "./AllFlat/getAllFlatProperty";
import GetAllTaxes from "./AllTaxes/getAllTaxes";
import AllDataClientsReduc from "./clients/clientsall";
import PropertyReducer from "./dashboard/getAllDashboard";
import AllGetAssignPer from "./assignpermission/allpermissionassign";
import GetAllPayments from "./AllPayments/GetAllPayments";
import GetAllUsers from "./users/getAllusers";
import AllNotifications from "./notification/allnotification";
import GetAllMaintenanceType from "./MaintenanceType/allMaintenanceType";
import GetAllMaintenance from "./AllMaintenance/allMaintenance";
import AllActiveMaintenanceType from "./MaintenanceType/allActiveMaintenanceType";
import PremisesFIlterData from "./premisesfilter";
import GetAllActiveTax from "./AllTaxes/getAllActiveTax";
import AllClientsDetials from "./clients/clientDetails";
import ReportAllGetREduc from "./reports/allreportred";
import ReportByyClieReducer from "./reports/reportbyclient";
import ReportByMonthReducc from "./reports/reportbymonth";
import ReportByPayReducerrr from "./reports/reportbypayment";
import ReportbyPropertyy from "./reports/reportbyproperties";
import ReportByFaltsShoppss from "./reports/reportsbyflsstshops";
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  GetAllPropertyType,
  GetAllAmenities,
  GetAllFlatSize,
  GetAllTaxes,
  AllDataClientsReduc,
  GetAllActiveFlatSize,
  GetAllActiveProperty,
  GetAllActiveAmenities,
  GetAllActivePropertyType,
  GetAllFlat,
  GetAllProperty,
  GetFlatPremesis,
  GetAllUsers,
  AllNotifications,
  GetAllMaintenance,
  menu,
  RegisterOwnerBrandReducer,
  // registerBrandUser,
  CreateRolssseeeReducer,
  ViewAllRolesReduncerre,
  AssignPermisionsReducer,
  // Reports,
  GetAllPermissionReducer,
  ViewAllRedUsers,
  // AllBatchesReducers,
  EditUserDataReducer,
  ViewallBrandssRedUsers,
  BrandDetailsGetDataReducer,
  AllGetCateeReducers,
  // SubAllCateReducers,
  AllCompaniesReducers,
  AllCountReducers,
  CompanyDetailingReducer,
  // AllActiveCompanies,
  AllProducts,
  AllRandomCode,
  AllActiveProducts,
  GetRolePermissions,
  // SMSReply,

  ExportReducerCsv,
  CSvCompanyReducer,
  CSVCategories,
  AllCsvRadmon,
  // CSvQRReducer,
  // ReportProblemReducer,
  // ProgressReducer,
  MapDataReducers,
  PropertyReducer,
  AllGetAssignPer,
  Notificationgetall,
  GetAllPayments,
  GetAllMaintenanceType,
  AllActiveMaintenanceType,
  PremisesFIlterData,
  GetAllActiveTax,
  AllAssignPreDetails,
  AllClientsDetials,
  ReportAllGetREduc,
  ReportByyClieReducer,
  ReportByMonthReducc,
  ReportByPayReducerrr,
  ReportbyPropertyy,
  ReportByFaltsShoppss,
});

export default reducers;
